import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '' || localStorage.getItem('token'),
    isLogin: false,
    search_key: ''
  },
  getters: {
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      localStorage.setItem('token',token)
    },
    SET_SEARCH: (state, val) => {
      state.search_key = val
    },
  },
  actions: {
  },
  modules: {
  }
})
