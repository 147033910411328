<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  padding: 0;
  margin: 0;
}

</style>
