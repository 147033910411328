import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchList.vue')
  },
  {
    path: '/topic-detail',
    name: 'topic',
    component: () => import('../views/TopicDetail.vue')
  },
  {
    path: '/history-detail',
    name: 'history',
    component: () => import('../views/HistoryDetail.vue')
  },
  {
    path: '/topic-list',
    name: 'topic-list',
    component: () => import('../views/TopicList.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(to,'3444')
//   next()
// })
export default router
