import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/util/rem'
import "fullpage.js"
// import 'vue-fullpage.js/dist/style.css'
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
createApp(App).use(store).use(router).use(VueFullPage).use(ElementPlus).mount('#app')
